<template>
  <div class="disclosure">
    <h1>EV6 First Edition 1k Gift Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">Effective as of February 2022</p>
    <p>
      Electrify America and Kia America are pleased to provide this promotional charging plan (“Plan”) to drivers of new
      2022 Kia EV6 First Edition vehicles. The Plan is available to drivers who reserved an EV6 First Edition, and
      purchased or leased the vehicle new from an authorized Kia retailer. By clicking “Agree” below, you agree to
      participate in this promotion, subject to your compliance with these terms and conditions (“Plan Terms”).
    </p>
    <p>
      Participation in the Plan requires an Electrify America account, the Electrify America mobile app, enrollment in
      the Plan on the app, and acceptance of the Electrify America Terms of Use governing your use of the app and
      Electrify America’s network. Your mobile carrier’s messaging, data, and other rates and fees may apply to your use
      of the Electrify America app.
    </p>
    <p>
      Immediately upon your registration and acceptance of these Plan Terms, you will be enrolled in the Plan for 1,000
      kWh of DC fast (CCS) or Level 2 charging at no additional cost for your EV6 on Electrify America’s network of
      electric vehicle charging stations. This Plan shall continue for a 36-month period starting from the date of
      vehicle purchase (provided to Electrify America by your vehicle’s manufacturer) until the benefit is depleted or
      unless earlier terminated by Electrify America as described below.
    </p>
    <p>
      After enrollment in this Plan, you can redeem your 1,000 kWh of charging under this Plan at any Electrify America
      charging station, <strong>exclusively</strong> by using the Electrify America app “Swipe to start” or NFC Pass
      feature with the EV6 First Edition 1k Gift Plan selected.
      <strong
        >The charging included under this Plan may not be initiated using the interface screen or buttons on the
        charger.</strong
      >
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this promotion.
      <strong
        >If you have expended the full 1,000 kWh amount provided under the Plan mid-session, or if the Plan otherwise
        terminates or expires as set forth herein, charging will continue uninterrupted at the prevailing Electrify
        America Pass charging price.</strong
      >
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this complimentary credit for charging the EV6 whose VIN you submitted when
      signing up for this Plan on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account,</strong> whether owned by you or
      others.
    </p>
    <p>
      You may <strong>not</strong> use this complimentary charging in connection with any taxi, ride-share, or other
      similar service, or for any other commercial purpose.
      <strong>This promotion is for your personal, non-commercial use only.</strong>
    </p>
    <p>
      Complimentary charging <strong>does not include</strong> any idle fees and their associated applicable taxes
      assessed at the charging station. You will be responsible for paying such fees through your account if you incur
      them during a charging session.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the EV6 when charging at
      an Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the EV6 at the charging station.
    </p>
    <p>
      Once the 1,000 kWh of complimentary charging is depleted, you will not be able to reload, renew, or add more
      credit to the EV6 First Edition 1k Gift Plan. Please refer to the information on the
      <strong>“Plan Details”</strong> section of the app to keep track of the amount of time remaining in this
      promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about how you can pay through your account for idle fees and applicable
      taxes as well as for charging session time outside of the complimentary charging offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, provided that Electrify America will send an email to the
      address associated with your account in the event of any change to the scheduled end date of this promotion.
      Following the termination or expiration of this Plan for any reason, your access to the complimentary promotion
      will immediately cease, and you may not use or redeem any of the related benefits. At that time, your account will
      automatically convert to a basic Electrify America Pass account, at no additional fee or charge to you (neither
      one-time nor recurring). You may, but are not obligated to, sign up for a different type of subscription via the
      Electrify America mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ev6-first-edition-1k-gift-plan-disclosure',
  metaInfo: {
    title: 'EV6 First Edition 1k Gift Plan | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/ev6-first-edition-1k-gift-plan-disclosure/' }],
  },
};
</script>
